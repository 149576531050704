// src/HomePage.js

import React, { useState, useEffect } from 'react';
import Navbar from './Navbar';
import { Link, useLocation } from 'react-router-dom';

function HomePage() {
  const [isScrolled, setIsScrolled] = useState(false);
  const [isIOS, setIsIOS] = useState(false);
  const [showInstructions, setShowInstructions] = useState(false);
  const [isInStandaloneMode, setIsInStandaloneMode] = useState(false);
  const location = useLocation();

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 50);
    };
    window.addEventListener('scroll', handleScroll);

    // Détection iOS
    const userAgent = window.navigator.userAgent;
    const isStandalone = window.matchMedia('(display-mode: standalone)').matches;

    // Vérifier si l'utilisateur est sur iOS et pas en mode standalone (installé)
    if (/iPhone|iPad|iPod/i.test(userAgent) && !isStandalone) {
      setIsIOS(true);
      setShowInstructions(true); // Obliger à afficher les instructions sur iOS
    }

    // Vérifier si l'application est déjà installée
    if (isStandalone) {
      setIsInStandaloneMode(true);
    }

    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  // Bloquer l'accès à toute autre page si l'application n'est pas installée
  useEffect(() => {
    if (isIOS && !isInStandaloneMode) {
      setShowInstructions(true);
    }
  }, [location, isIOS, isInStandaloneMode]);

  return (
    <div className="relative">
      <Navbar isScrolled={isScrolled} />
      <div
        className="bg-cover bg-center h-screen"
        style={{ backgroundImage: `url('https://via.placeholder.com/1920x1080')` }}
      >
        <div className="flex flex-col items-center justify-center h-full bg-black bg-opacity-50">
          <h1 className="text-white text-5xl font-bold mb-4">Bienvenue sur ton site de streaming</h1>
          <button className="bg-red-600 text-white px-6 py-3 rounded-full text-lg">
            <Link to="/login">Se connecter</Link>
          </button>
        </div>
      </div>

      {/* Instructions pour iOS - Bloque l'accès jusqu'à installation */}
      {isIOS && showInstructions && (
        <div className="fixed inset-0 bg-black bg-opacity-90 flex flex-col justify-center items-center z-50">
          <div className="bg-gray-900 text-white p-10 rounded-xl shadow-lg text-center w-full max-w-md transform transition-all duration-500 ease-in-out scale-105 hover:scale-110">
            <h2 className="text-3xl font-bold mb-6">Installez notre application</h2>
            <p className="text-lg mb-8">
              Pour une meilleure expérience, ajoutez ce site à votre écran d'accueil en suivant ces étapes :
            </p>
            <div className="flex flex-col items-center space-y-6">
              <div className="flex flex-col items-center">
                <p className="text-base mb-2">1. Appuyez sur l'icône de partage en bas de votre écran.</p>
                <img
                  src="https://upload.wikimedia.org/wikipedia/commons/thumb/5/5f/Share_icon_Safari_iOS.png/120px-Share_icon_Safari_iOS.png"
                  alt="Icône de partage Safari"
                  className="w-14 h-14 mb-4 transition-transform duration-300 ease-in-out hover:scale-110"
                />
              </div>
              <div className="flex flex-col items-center">
                <p className="text-base mb-2">2. Sélectionnez "Ajouter à l'écran d'accueil".</p>
                <img
                  src="https://upload.wikimedia.org/wikipedia/commons/thumb/b/bd/Add_to_Home_Screen_Icon_iOS.png/120px-Add_to_Home_Screen_Icon_iOS.png"
                  alt="Ajouter à l'écran d'accueil"
                  className="w-14 h-14 mb-4 transition-transform duration-300 ease-in-out hover:scale-110"
                />
              </div>
              <div className="flex flex-col items-center">
                <p className="text-base mb-2">3. Cliquez sur "Ajouter" dans le coin supérieur droit.</p>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default HomePage;
