// src/AddMoviePage.js
import React, { useState, useEffect, useContext } from 'react';
import { AuthContext } from './AuthContext';

import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

function AddMoviePage() {
  const { token, user } = useContext(AuthContext);
  const [title, setTitle] = useState('');
  const [posterImage, setPosterImage] = useState(null);
  const [movieFile, setMovieFile] = useState(null);
  const [categories, setCategories] = useState([]);
  const [categoryId, setCategoryId] = useState('');
  const [uploadProgress, setUploadProgress] = useState(0);
  const navigate = useNavigate();

  useEffect(() => {
    if (user && user.role !== 'admin') {
      navigate('/movies');
    }

    // Fetch categories from backend
    fetch('https://api.aribo.me/api/categories', {
      headers: { Authorization: `Bearer ${token}` },
    })
      .then((res) => res.json())
      .then((data) => {
        setCategories(data);
        setCategoryId(data[0]?.id || '');
      })
      .catch((err) => console.error(err));
  }, [token, user, navigate]);

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!movieFile) {
      toast.error('Veuillez sélectionner un fichier vidéo', {
        position: 'top-right',
        autoClose: 3000,
      });
      return;
    }

    const formData = new FormData();
    formData.append('title', title);
    formData.append('posterImage', posterImage);
    formData.append('movieFile', movieFile);
    formData.append('category_id', categoryId);

    const xhr = new XMLHttpRequest();
    xhr.open('POST', 'https://api.aribo.me/api/movies');
    xhr.setRequestHeader('Authorization', `Bearer ${token}`);

    xhr.upload.onprogress = (event) => {
      if (event.lengthComputable) {
        const percentComplete = Math.round((event.loaded / event.total) * 100);
        setUploadProgress(percentComplete);
      }
    };

    xhr.onload = () => {
      if (xhr.status === 201) {
        toast.success('Film ajouté avec succès', {
          position: 'top-right',
          autoClose: 3000,
        });
        setTitle('');
        setPosterImage(null);
        setMovieFile(null);
        setUploadProgress(0);
      } else {
        toast.error('Erreur lors de l\'ajout du film', {
          position: 'top-right',
          autoClose: 3000,
        });
      }
    };

    xhr.onerror = () => {
      toast.error('Erreur réseau', {
        position: 'top-right',
        autoClose: 3000,
      });
    };

    xhr.send(formData);
  };

  return (
    <div className="min-h-screen bg-gray-100 pt-16">

      <div className="flex items-center justify-center">
        <form onSubmit={handleSubmit} className="bg-white p-8 rounded shadow-md w-96">
          <h2 className="text-2xl font-bold mb-6 text-center">Ajouter un film</h2>
          <div className="mb-4">
            <label className="block text-gray-700">Titre :</label>
            <input
              type="text"
              className="w-full border border-gray-300 p-2 rounded mt-1"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              required
            />
          </div>
          <div className="mb-4">
            <label className="block text-gray-700">Affiche du film (image) :</label>
            <input
              type="file"
              accept="image/*"
              onChange={(e) => setPosterImage(e.target.files[0])}
              required
            />
          </div>
          <div className="mb-6">
            <label className="block text-gray-700">Fichier vidéo (MP4) :</label>
            <input
              type="file"
              accept="video/mp4"
              onChange={(e) => setMovieFile(e.target.files[0])}
              required
            />
          </div>
          <div className="mb-4">
            <label className="block text-gray-700">Catégorie :</label>
            <select
              className="w-full border border-gray-300 p-2 rounded mt-1"
              value={categoryId}
              onChange={(e) => setCategoryId(e.target.value)}
              required
            >
              {categories.map((category) => (
                <option key={category.id} value={category.id}>
                  {category.name}
                </option>
              ))}
            </select>
          </div>
          {uploadProgress > 0 && (
            <div className="mb-4">
              <div className="w-full bg-gray-200 rounded-full h-4">
                <div
                  className="bg-blue-600 h-4 rounded-full"
                  style={{ width: `${uploadProgress}%` }}
                ></div>
              </div>
              <p className="text-center mt-2">{uploadProgress}%</p>
            </div>
          )}
          <button type="submit" className="w-full bg-green-600 text-white py-2 rounded">
            Ajouter le film
          </button>
        </form>
      </div>
    </div>
  );
}

export default AddMoviePage;
