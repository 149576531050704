// src/MoviePlayerPage.js

import React, { useEffect, useState, useContext, useRef } from 'react';
import { useParams } from 'react-router-dom';
import Navbar from './Navbar';
import { AuthContext } from './AuthContext';
import axios from 'axios';

function MoviePlayerPage() {
  const { id } = useParams();
  const { token } = useContext(AuthContext);
  const [movie, setMovie] = useState(null);
  const [currentTime, setCurrentTime] = useState(0);
  const videoRef = useRef(null);

  // Récupérer les informations du film
  useEffect(() => {
    axios
      .get(`https://api.aribo.me/api/movies/${id}`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => {
        setMovie(response.data);
      })
      .catch((error) => {
        console.error('Erreur de récupération du film :', error);
      });
  }, [id, token]);

  // Récupérer la position de lecture depuis le backend
  useEffect(() => {
    if (token && movie) {
      axios
        .get(`https://api.aribo.me/api/movies/${id}/progress`, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((response) => {
          setCurrentTime(response.data.position || 0);
        })
        .catch((error) => {
          console.error('Erreur lors de la récupération de la progression du film :', error);
        });
    }
  }, [id, token, movie]);

  // Mettre à jour la position de lecture dans le backend
  const handleTimeUpdate = () => {
    const current = videoRef.current.currentTime;
    axios
      .post(
        `https://api.aribo.me/api/movies/${id}/progress`,
        { position: current },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .catch((error) => {
        console.error('Erreur lors de la mise à jour de la progression du film :', error);
      });
  };

  // Positionner la vidéo au bon endroit lorsque le composant est monté
  useEffect(() => {
    if (videoRef.current && currentTime > 0) {
      videoRef.current.currentTime = currentTime;
    }
  }, [currentTime]);

  // Fonction pour jouer la vidéo suite à une interaction utilisateur (nécessaire pour Safari)
  const playVideoOnInteraction = () => {
    if (videoRef.current) {
      videoRef.current.muted = false; // Désactiver le mode muet après interaction
      videoRef.current.play();
    }
  };

  if (!movie) {
    return (
      <div className="flex items-center justify-center min-h-screen bg-black">
        <div className="text-white text-lg">Chargement...</div>
        <div className="w-10 h-10 ml-4 border-4 border-t-transparent border-white rounded-full animate-spin"></div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gradient-to-b from-black to-gray-900">
      <Navbar isScrolled={true} />
      <div className="flex flex-col items-center justify-center pt-16 px-4">
        {/* Titre du film */}
        <h1 className="text-white text-4xl md:text-5xl font-extrabold mb-8 text-center transform transition duration-500 ease-in-out hover:scale-105 shadow-md">
          {movie.title}
        </h1>

        {/* Conteneur vidéo avec bordures et ombres */}
        <div className="relative w-full max-w-5xl">
          <div className="absolute inset-0 bg-gradient-to-b from-transparent to-black opacity-60 rounded-lg"></div>
          <div className="video-container w-full h-0 pb-[56.25%] relative">
            <video
              ref={videoRef}
              controls
              onTimeUpdate={handleTimeUpdate}
              onClick={playVideoOnInteraction} // Nécessaire pour lancer la vidéo sur Safari après interaction
              className="absolute top-0 left-0 w-full h-full rounded-lg shadow-2xl border-2 border-gray-800 hover:shadow-3xl hover:scale-105 transition-all duration-300 ease-in-out"
              src={`https://api.aribo.me${movie.file_path}`}
              playsInline // Important pour iOS/Safari afin de permettre la lecture dans le navigateur
              muted // Activer le mute par défaut pour permettre la lecture initiale sur iPhone
              crossOrigin="anonymous" // Important si le fichier est servi depuis un domaine différent
            >
              Votre navigateur ne supporte pas la lecture de vidéos.
            </video>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MoviePlayerPage;
