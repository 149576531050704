// src/App.js
import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { AuthProvider } from './AuthContext';
import PrivateRoute from './PrivateRoute';
import HomePage from './HomePage';
import LoginPage from './LoginPage';
import RegisterPage from './RegisterPage';
import MoviesPage from './MoviesPage';
import SearchResultsPage from './SearchResultsPage';
import MoviePlayerPage from './MoviePlayerPage';
import AdminPage from './AdminPage';
import AdminDashboard from './AdminDashboard';
import AdminMoviesPage from './AdminMoviesPage';
import AddMoviePage from './AddMoviePage';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function App() {
  return (
    <AuthProvider>
      <Router>
        <Routes>
          {/* Routes publiques */}
          <Route path="/" element={<HomePage />} />
          <Route path="/login" element={<LoginPage />} />
          <Route path="/register" element={<RegisterPage />} />

          {/* Routes privées */}
          <Route
            path="/movies"
            element={
              <PrivateRoute>
                <MoviesPage />
              </PrivateRoute>
            }
          />
          <Route
            path="/search"
            element={
              <PrivateRoute>
                <SearchResultsPage />
              </PrivateRoute>
            }
          />
          <Route
            path="/movies/:id/play"
            element={
              <PrivateRoute>
                <MoviePlayerPage />
              </PrivateRoute>
            }
          />

          {/* Routes d'administration */}
          <Route
            path="/admin"
            element={
              <PrivateRoute>
                <AdminPage />
              </PrivateRoute>
            }
          >
            <Route path="dashboard" element={<AdminDashboard />} />
            <Route path="movies" element={<AdminMoviesPage />} />
            <Route path="add-movie" element={<AddMoviePage />} />
            {/* Vous pouvez ajouter d'autres sous-routes ici */}
          </Route>

          {/* Route par défaut si aucune autre route ne correspond */}
          <Route path="*" element={<HomePage />} />
        </Routes>
        <ToastContainer />
      </Router>
    </AuthProvider>
  );
}

export default App;
