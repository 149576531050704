// src/LoginPage.js
import React, { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from './AuthContext';
import Navbar from './Navbar';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';


function LoginPage() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const { login } = useContext(AuthContext);
  const navigate = useNavigate();

  const handleSubmit = (e) => {
    e.preventDefault();

    fetch('https://api.aribo.me/api/login', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ email, password }),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.token) {
          login(data.token);
          toast.success('Connexion réussie !', {
            position: 'top-right',
            autoClose: 3000,
          });
          navigate('/movies');
        } else {
          toast.error(data.message, {
            position: 'top-right',
            autoClose: 3000,
          });
        }
      })
      .catch((err) => console.error(err));
  };

  return (
    <div>
      <Navbar isScrolled={true} />
      <div className="min-h-screen flex items-center justify-center bg-gray-100">
        <form onSubmit={handleSubmit} className="bg-white p-8 rounded shadow-md w-96">
          <h2 className="text-2xl font-bold mb-6 text-center">Connexion</h2>
          <div className="mb-4">
            <label className="block text-gray-700">Email :</label>
            <input
              type="email"
              className="w-full border border-gray-300 p-2 rounded mt-1"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </div>
          <div className="mb-6">
            <label className="block text-gray-700">Mot de passe :</label>
            <input
              type="password"
              className="w-full border border-gray-300 p-2 rounded mt-1"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
          </div>
          <button type="submit" className="w-full bg-blue-600 text-white py-2 rounded">
            Se connecter
          </button>
          <p className="mt-4 text-center">
            Pas encore de compte ?{' '}
            <Link to="/register" className="text-blue-600">
              Inscrivez-vous
            </Link>
          </p>
        </form>
      </div>
    </div>
  );
}

export default LoginPage;
