// src/AdminPage.js
import React, { useContext } from 'react';
import { AuthContext } from './AuthContext';
import { Navigate, Outlet } from 'react-router-dom';
import Navbar from './Navbar';
import Sidebar from './Sidebar';

function AdminPage() {
  const { token, user } = useContext(AuthContext);

  if (!token || !user || user.role !== 'admin') {
    return <Navigate to="/" />;
  }

  return (
    <div className="min-h-screen bg-gray-900">
      {/* Inclure la Navbar */}
      <Navbar isScrolled={true} />
      <div className="flex">
        {/* Sidebar */}
        <Sidebar />
        {/* Contenu principal */}
        <div className="flex-1 p-4 pt-20">
          <Outlet />
        </div>
      </div>
    </div>
  );
}

export default AdminPage;
