// src/Navbar.js
import React, { useContext, useState } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { AuthContext } from './AuthContext';
import { FaSearch } from 'react-icons/fa';

function Navbar({ isScrolled }) {
  const { token, logout, user } = useContext(AuthContext);
  const [searchTerm, setSearchTerm] = useState('');
  const navigate = useNavigate();
  const location = useLocation();

  const handleSearchSubmit = (e) => {
    e.preventDefault();
    if (searchTerm.trim() !== '') {
      navigate(`/search?q=${encodeURIComponent(searchTerm)}`);
    }
  };

  return (
    <nav
      className={`fixed top-0 left-0 w-full z-10 transition-all duration-300 ${
        isScrolled ? 'bg-black bg-opacity-90' : 'bg-transparent'
      }`}
    >
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex items-center justify-between h-16">
          {/* Logo */}
          <div className="flex-shrink-0">
            <Link to="/">
              <span className="text-white text-3xl">🎥</span>
            </Link>
          </div>
          {/* Barre de recherche */}
          <form onSubmit={handleSearchSubmit} className="flex items-center w-full max-w-md mx-4">
            <div className="relative w-full">
              <input
                type="text"
                placeholder="Rechercher un film..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                className="w-full p-2 pl-10 bg-gray-800 text-white border border-gray-700 rounded-full focus:outline-none focus:ring-2 focus:ring-red-600"
              />
              <FaSearch className="absolute left-3 top-2 text-gray-400" />
            </div>
          </form>
          {/* Liens */}
          <div>
            {token ? (
              <div className="flex items-center">
                <Link
                  to="/movies"
                  className="text-white px-3 py-2 rounded-md text-sm font-medium"
                >
                  Films
                </Link>
                {user && user.role === 'admin' && (
                  <Link
                    to="/admin"
                    className="text-white px-3 py-2 rounded-md text-sm font-medium"
                  >
                    Administration
                  </Link>
                )}
                <button
                  onClick={() => {
                    logout();
                    navigate('/');
                  }}
                  className="text-white px-3 py-2 rounded-md text-sm font-medium hover:bg-red-600 transition duration-300"
                >
                  Déconnexion
                </button>
              </div>
            ) : (
              <Link
                to="/login"
                className="text-white px-3 py-2 rounded-md text-sm font-medium hover:bg-white hover:text-black transition duration-300"
              >
                Se connecter
              </Link>
            )}
          </div>
        </div>
      </div>
    </nav>
  );
}

export default Navbar;
