// src/MoviesPage.js
import React, { useState, useEffect, useContext } from 'react';
import Navbar from './Navbar';
import { AuthContext } from './AuthContext';

function MoviesPage() {
  const { token } = useContext(AuthContext);
  const [moviesByCategory, setMoviesByCategory] = useState({});

  useEffect(() => {
    fetch('https://api.aribo.me/api/movies', {
      headers: { Authorization: `Bearer ${token}` },
    })
      .then((res) => res.json())
      .then((data) => {
        // Organiser les films par catégorie
        const moviesGrouped = data.reduce((acc, movie) => {
          const category = movie.category_name || 'Autres';
          if (!acc[category]) {
            acc[category] = [];
          }
          acc[category].push(movie);
          return acc;
        }, {});
        setMoviesByCategory(moviesGrouped);
      })
      .catch((err) => console.error(err));
  }, [token]);

  return (
    <div className="min-h-screen bg-gray-900 pt-16">
      <Navbar isScrolled={true} />
      <div className="max-w-7xl mx-auto p-4">
        {Object.keys(moviesByCategory).map((category) => (
          <div key={category}>
            <h2 className="text-white text-3xl font-bold mb-6">{category}</h2>
            <div className="grid grid-cols-2 md:grid-cols-4 gap-6 mb-8">
              {moviesByCategory[category].map((movie) => (
                <div
                  key={movie.id}
                  className="bg-gray-800 p-4 rounded-lg shadow-lg hover:shadow-xl transform hover:scale-105 transition-transform cursor-pointer"
                  onClick={() => (window.location.href = `/movies/${movie.id}/play`)}
                >
                  <img
                    src={
                      movie.poster_url
                        ? `https://api.aribo.me${movie.poster_url}`
                        : 'https://via.placeholder.com/200x300'
                    }
                    alt={movie.title}
                    className="mb-4 w-full h-64 object-cover rounded-md"
                  />
                  <h3 className="text-white text-xl font-semibold">{movie.title}</h3>
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
      {/* Styles de la barre de défilement personnalisée pour la page entière */}
      <style jsx global>{`
        /* Personnalisation de la barre de défilement pour la page entière */
        body {
          background-color: #1a202c; /* Couleur de fond correspondant à bg-gray-900 */
        }
        body::-webkit-scrollbar {
          width: 8px;
        }
        body::-webkit-scrollbar-thumb {
          background-color: rgba(0, 0, 0, 0.7);
          border-radius: 10px;
          transition: background-color 0.3s, width 0.3s;
        }
        body:hover::-webkit-scrollbar-thumb {
          background-color: rgba(0, 0, 0, 0.9);
        }
        body::-webkit-scrollbar-track {
          background-color: #1a202c; /* Couleur de la piste correspondant à bg-gray-900 */
        }
      `}</style>
    </div>
  );
}

export default MoviesPage;
