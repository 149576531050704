// src/SearchResultsPage.js
import React, { useState, useEffect, useContext } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Navbar from './Navbar';
import { AuthContext } from './AuthContext';

function SearchResultsPage() {
  const { token } = useContext(AuthContext);
  const [movies, setMovies] = useState([]);
  const location = useLocation();
  const navigate = useNavigate();

  const query = new URLSearchParams(location.search).get('q');

  useEffect(() => {
    if (!query) {
      navigate('/movies');
      return;
    }

    fetch(`https://api.aribo.me/api/movies/search?q=${encodeURIComponent(query)}`, {
      headers: { Authorization: `Bearer ${token}` },
    })
      .then((res) => res.json())
      .then((data) => setMovies(data))
      .catch((err) => console.error(err));
  }, [query, token, navigate]);

  return (
    <div className="min-h-screen bg-gray-900 pt-16">
      <Navbar isScrolled={true} />
      <div className="max-w-7xl mx-auto p-4">
        <h2 className="text-white text-3xl font-bold mb-6">
          Résultats de recherche pour "{query}"
        </h2>
        {movies.length > 0 ? (
          <div className="grid grid-cols-2 md:grid-cols-4 gap-6">
            {movies.map((movie) => (
              <div
                key={movie.id}
                className="bg-gray-800 p-4 rounded-lg shadow-lg hover:shadow-xl transform hover:scale-105 transition-transform cursor-pointer"
                onClick={() => navigate(`/movies/${movie.id}/play`)}
              >
                <img
                  src={
                    movie.poster_url
                      ? `https://api.aribo.me${movie.poster_url}`
                      : 'https://via.placeholder.com/200x300'
                  }
                  alt={movie.title}
                  className="mb-4 w-full h-64 object-cover rounded-md"
                />
                <h3 className="text-white text-xl font-semibold">{movie.title}</h3>
              </div>
            ))}
          </div>
        ) : (
          <p className="text-white">Aucun film trouvé pour cette recherche.</p>
        )}
      </div>
    </div>
  );
}

export default SearchResultsPage;
