import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { FaHome, FaFilm, FaPlus } from 'react-icons/fa';

function Sidebar() {
  const location = useLocation();

  const menuItems = [
    {
      name: 'Main Dashboard',
      path: '/admin/dashboard',
      icon: <FaHome />,
    },
    {
      name: 'Gérer les films',
      path: '/admin/movies',
      icon: <FaFilm />,
    },
    {
      name: 'Ajouter un film',
      path: '/admin/add-movie',
      icon: <FaPlus />,
    },
  ];

  return (
    <div className="w-64 bg-zinc-950 text-white h-screen pt-16 sticky top-0">
      {/* Logo */}
      <div className="p-4 font-bold text-xl border-b border-gray-700 flex items-center justify-center">
        <div className="mr-2 flex h-[40px] w-[40px] items-center justify-center rounded-md bg-white text-zinc-950">
          {/* Icône du logo */}
          {/* ... */}
        </div>
        <h5 className="mr-2 text-2xl font-bold leading-5 text-white">
          Floxie
        </h5>
        <div className="inline-flex items-center rounded-full font-semibold my-auto w-max px-2 py-0.5 text-xs bg-zinc-800 text-white">
          ADMIN
        </div>
      </div>
      {/* Menu */}
      <ul>
        {menuItems.map((item) => (
          <li key={item.name}>
            <Link
              to={item.path}
              className={`flex items-center rounded-lg py-3 pl-8 font-medium transition-colors ${
                location.pathname === item.path
                  ? 'bg-white text-zinc-950'
                  : 'text-zinc-400 hover:bg-gray-800'
              }`}
              style={{ borderRadius: '0px', width: '100%' }}
            >
              <div className="flex items-center">
                <div className="mr-3 mt-1.5 text-inherit">
                  {React.cloneElement(item.icon, {
                    className: '-mt-[7px] h-4 w-4 text-inherit',
                  })}
                </div>
                <p className="text-sm font-medium text-inherit">
                  {item.name}
                </p>
              </div>
            </Link>
          </li>
        ))}
      </ul>
    </div>
  );
}

export default Sidebar;