// src/AdminDashboard.js

import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { AuthContext } from './AuthContext';
import { Bar, Line } from 'react-chartjs-2';
import Chart from 'chart.js/auto';

function AdminDashboard() {
  const { token } = useContext(AuthContext);
  const [systemStats, setSystemStats] = useState(null);
  const [userStats, setUserStats] = useState(null);
  const [activeUsers, setActiveUsers] = useState(null);
  const [cpuHistory, setCpuHistory] = useState([]);
  const [activeUsersHistory, setActiveUsersHistory] = useState([]);
  const [networkStats, setNetworkStats] = useState(null);

  useEffect(() => {
    let isMounted = true;

    const fetchData = async () => {
      try {
        await fetchSystemStats();
        await fetchUserStats();
        await fetchNetworkStats();
        await fetchActiveUsers();
        await fetchCpuHistory();
        await fetchActiveUsersHistory();
      } catch (error) {
        console.error('Erreur lors de la récupération des statistiques :', error);
      }
    };

    fetchData(); // Récupération initiale

    const interval = setInterval(() => {
      if (isMounted) {
        fetchData();
      }
    }, 5000); // Actualiser toutes les 5 secondes

    return () => {
      isMounted = false;
      clearInterval(interval);
    };
  }, [token]);

  const fetchSystemStats = async () => {
    try {
      const response = await axios.get('https://api.aribo.me/api/stats/system', {
        headers: { Authorization: `Bearer ${token}` },
      });
      setSystemStats(response.data);
    } catch (error) {
      console.error('Erreur lors de la récupération des statistiques système :', error);
    }
  };

  const fetchUserStats = async () => {
    try {
      const response = await axios.get('https://api.aribo.me/api/stats/users', {
        headers: { Authorization: `Bearer ${token}` },
      });
      setUserStats(response.data);
    } catch (error) {
      console.error('Erreur lors de la récupération des statistiques des utilisateurs :', error);
    }
  };

  const fetchNetworkStats = async () => {
    try {
      const response = await axios.get('https://api.aribo.me/api/stats/network', {
        headers: { Authorization: `Bearer ${token}` },
      });
      setNetworkStats(response.data);
    } catch (error) {
      console.error('Erreur lors de la récupération des statistiques réseau :', error);
    }
  };

  const fetchActiveUsers = async () => {
    try {
      const response = await axios.get('https://api.aribo.me/api/stats/active-users', {
        headers: { Authorization: `Bearer ${token}` },
      });
      setActiveUsers(response.data.activeUserCount);
    } catch (error) {
      console.error('Erreur lors de la récupération des utilisateurs actifs :', error);
    }
  };

  const fetchCpuHistory = async () => {
    try {
      const response = await axios.get('https://api.aribo.me/api/stats/cpu-history', {
        headers: { Authorization: `Bearer ${token}` },
      });
      setCpuHistory(response.data.reverse());
    } catch (error) {
      console.error('Erreur lors de la récupération de l\'historique de la charge CPU :', error);
    }
  };

  const fetchActiveUsersHistory = async () => {
    try {
      const response = await axios.get('https://api.aribo.me/api/stats/active-users-history', {
        headers: { Authorization: `Bearer ${token}` },
      });
      setActiveUsersHistory(response.data.reverse());
    } catch (error) {
      console.error('Erreur lors de la récupération de l\'historique des utilisateurs actifs :', error);
    }
  };

  if (!systemStats || !userStats || !networkStats || activeUsers === null) {
    return (
      <div className="flex items-center justify-center h-screen">
        <div className="text-white text-xl">Chargement des statistiques...</div>
      </div>
    );
  }

  // Extraction des données
  const { uptime, totalMemory, usedMemory, memoryUsage, cpuLoad, diskUsage } = systemStats || {};
  const { userCount } = userStats || {};
  const { totalBytes } = networkStats || {};

  // Vérifications avant d'utiliser toFixed
  const formattedCpuLoad = cpuLoad ? cpuLoad.toFixed(2) : '0.00';
  const formattedTotalBytes = totalBytes ? (totalBytes / (1024 ** 3)).toFixed(2) : '0.00';

  // Formatage des valeurs
  const formatBytes = (bytes) => {
    if (!bytes) return '0 Go';
    const gigabytes = bytes / (1024 ** 3);
    return `${gigabytes.toFixed(2)} Go`;
  };

  const formatUptime = (seconds) => {
    if (!seconds) return '0j 0h 0m';
    const d = Math.floor(seconds / (3600 * 24));
    const h = Math.floor((seconds % (3600 * 24)) / 3600);
    const m = Math.floor((seconds % 3600) / 60);
    return `${d}j ${h}h ${m}m`;
  };

  // Données pour les graphiques
  const cpuData = {
    labels: ['Charge CPU (%)'],
    datasets: [
      {
        label: 'Charge CPU',
        data: [formattedCpuLoad],
        backgroundColor: 'rgba(37, 99, 235, 0.7)',
        borderColor: 'rgba(37, 99, 235, 1)',
        borderWidth: 1,
      },
    ],
  };

  const cpuHistoryData = {
    labels: cpuHistory.map(entry => new Date(entry.timestamp).toLocaleTimeString()),
    datasets: [
      {
        label: 'Charge CPU (%)',
        data: cpuHistory.map(entry => entry.cpu_load.toFixed(2)),
        backgroundColor: 'rgba(37, 99, 235, 0.7)',
        borderColor: 'rgba(37, 99, 235, 1)',
        borderWidth: 1,
        fill: false,
      },
    ],
  };

  const activeUsersData = {
    labels: activeUsersHistory.map(entry => new Date(entry.timestamp).toLocaleTimeString()),
    datasets: [
      {
        label: 'Utilisateurs actifs',
        data: activeUsersHistory.map(entry => entry.active_users),
        backgroundColor: 'rgba(16, 185, 129, 0.7)',
        borderColor: 'rgba(16, 185, 129, 1)',
        borderWidth: 1,
        fill: false,
      },
    ],
  };

  const chartOptions = {
    maintainAspectRatio: false,
    plugins: {
      legend: {
        labels: {
          color: 'white',
        },
      },
    },
    scales: {
      x: {
        ticks: {
          color: 'white',
        },
        grid: {
          color: 'rgba(255, 255, 255, 0.1)',
        },
      },
      y: {
        beginAtZero: true,
        ticks: {
          color: 'white',
        },
        grid: {
          color: 'rgba(255, 255, 255, 0.1)',
        },
      },
    },
  };

  // Formatage des données du disque
  const formattedDiskUsed = formatBytes(diskUsage.used);
  const formattedDiskTotal = formatBytes(diskUsage.size);
  const diskUsagePercentage = ((diskUsage.used / diskUsage.size) * 100).toFixed(2);

  return (
    <div className="p-4 bg-gray-900 min-h-screen">
      <h1 className="text-white text-2xl font-bold mb-6">Tableau de bord administrateur</h1>
      <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-6">
        {/* Carte - Temps de fonctionnement */}
        <div className="bg-gray-800 rounded-lg shadow p-6">
          <h2 className="text-lg font-semibold text-white">Temps de fonctionnement</h2>
          <p className="mt-4 text-3xl font-bold text-white">{formatUptime(uptime)}</p>
        </div>
        {/* Carte - Trafic réseau total */}
        <div className="bg-gray-800 rounded-lg shadow p-6">
          <h2 className="text-lg font-semibold text-white">Trafic réseau total</h2>
          <p className="mt-4 text-3xl font-bold text-white">{formattedTotalBytes} Go</p>
        </div>
        {/* Carte - Utilisateurs inscrits */}
        <div className="bg-gray-800 rounded-lg shadow p-6">
          <h2 className="text-lg font-semibold text-white">Utilisateurs inscrits</h2>
          <p className="mt-4 text-3xl font-bold text-white">{userCount}</p>
        </div>
        {/* Carte - Utilisateurs actifs */}
        <div className="bg-gray-800 rounded-lg shadow p-6">
          <h2 className="text-lg font-semibold text-white">Utilisateurs actifs</h2>
          <p className="mt-4 text-3xl font-bold text-white">{activeUsers}</p>
        </div>
        {/* Carte - Utilisation du disque */}
        <div className="bg-gray-800 rounded-lg shadow p-6">
          <h2 className="text-lg font-semibold text-white">Utilisation du disque</h2>
          <div className="mt-4">
            <p className="text-3xl font-bold text-white">
              {formattedDiskUsed} / {formattedDiskTotal}
            </p>
            <div className="w-full bg-gray-700 rounded-full h-4 mt-4">
              <div
                className="bg-blue-500 h-4 rounded-full"
                style={{ width: `${diskUsagePercentage}%` }}
              ></div>
            </div>
          </div>
        </div>
        {/* Graphique - Historique de la charge CPU */}
        <div className="bg-gray-800 rounded-lg shadow p-6 col-span-1 md:col-span-2 h-64">
          <h2 className="text-lg font-semibold text-white mb-4">Historique de la charge CPU</h2>
          <Line data={cpuHistoryData} options={chartOptions} />
        </div>
        {/* Graphique - Utilisateurs actifs au fil du temps */}
        <div className="bg-gray-800 rounded-lg shadow p-6 col-span-1 md:col-span-2 h-64">
          <h2 className="text-lg font-semibold text-white mb-4">Utilisateurs actifs au fil du temps</h2>
          <Line data={activeUsersData} options={chartOptions} />
        </div>
      </div>
    </div>
  );
}

export default AdminDashboard;
